import { useState, useEffect, useRef } from "react";
import CopyIcon from "@mui/icons-material/FileCopy";
import bwipjs from "bwip-js";
import "./MainPage.css";
import {
  BellIcon,
  CoinIcon,
  LogoIcon,
  CashbackIcon,
  HeartIcon,
  HistoryIcon,
  RatingIcon,
  ReviewIcon,
  BoxIcon,
} from "../icons";

function MainPage() {
  const [balance, setBalance] = useState("");
  const [barcode, setBarcode] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [error, setError] = useState(false);
  const barcodeRef = useRef(null);

  useEffect(() => {
    const path = window.location.pathname;
    const newCardNumber = path.substring(path.lastIndexOf("/") + 1);
    setCardNumber(newCardNumber);
    //setBarcode(newCardNumber);

    const urlParams = new URLSearchParams(window.location.search);
    const isFull = urlParams.has("full");

    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    let barcodeUrl = `https://bebra.live/api/barcode/${newCardNumber}`;
    if (!isFull) {
      barcodeUrl += "?offline=true";
    }

    fetch(barcodeUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBarcode(result.data.barcode);
        setCardNumber(result.data.barcode.split("QR")[0]);
      })
      .catch((error) => {
        console.log("error", error);
        setError(true);
      });

    if (isFull) {
      fetch(`https://bebra.live/api/balance/${newCardNumber}`, requestOptions)
        .then((response) => response.json())
        .then((result) => setBalance(result.data.data[0].balance))
        .catch((error) => console.log("error", error));
    } else {
      setError(false);
    }
  }, []);

  useEffect(() => {
    if (barcode && barcodeRef.current) {
      const barcodeType = error ? "code128" : "pdf417";
      const barcodeText = error ? cardNumber : barcode;

      bwipjs.toCanvas(barcodeRef.current, {
        bcid: barcodeType,
        text: barcodeText,
        scale: 3,
        height: 8.5,
        includetext: false,
        textxalign: "center",
        backgroundcolor: back,
        barcolor: fore,
      });
    }
  }, [barcode, back, fore, error, cardNumber]);

  const handleClose = () => {
    console.log("Close button clicked");
    window.close();
  };

  return (
    <div className="App container">
      <div className="main_center mx-auto">
        <div className="top-container d-flex justify-content-between align-items-center">
          <p className="top-text ml-2">Главная</p>
          <BellIcon />
        </div>
        <div className="content">
          <div className="balance-container d-flex align-items-center">
            <p className="balance">
              <strong>{balance}</strong>
            </p>
            <CoinIcon />
            <p className="balance-small">{(balance / 10).toFixed(2)} ₽</p>
            <LogoIcon />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            {barcode && (
              <div
                className="barcode"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <canvas
                  ref={barcodeRef}
                  style={{ width: "100%", height: "auto" }}
                ></canvas>
              </div>
            )}
            {error && (
              <div
                className="barcode-error"
                style={{
                  width: "100%",
                  height: "4px",
                  backgroundColor: "#302e32",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  padding: "10px 0",
                  //borderBottomLeftRadius: "10px",
                  //borderBottomRightRadius: "10px",
                }}
              >
                Произошла ошибка, обновите страницу.
              </div>
            )}
          </div>

          <div className="cashback-container mt-3">
            <div className="cashback-header d-flex justify-content-between align-items-center">
              <p className="cashback-title">
                <strong>Кешбек в августе</strong>
              </p>
              <p className="cashback-level">1 уровень &gt;</p>
            </div>
            <div className="cashback-details d-flex align-items-center mt-2">
              <CashbackIcon />
              <p className="cashback-text ml-2">7,5% на всё</p>

              <HeartIcon />
              <p className="cashback-text ml-2">10% на 1 категорию</p>
            </div>
          </div>
        </div>

        <div className="icon-section d-flex justify-content-around align-items-start mt-4">
          <div className="icon-item">
            <HistoryIcon />
            <p>История покупок</p>
          </div>
          <div className="icon-item">
            <RatingIcon />
            <p>Оценка товаров</p>
          </div>
          <div className="icon-item">
            <ReviewIcon />
            <p>Проверки магазинов</p>
          </div>
        </div>
        <div
          className="promo-container d-flex justify-content-between align-items-center mt-4"
          style={{
            width: "100%",
            borderRadius: "9999px",
            height: "56px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "16px",
            backgroundColor: "red",
            zIndex: 10,
            overflow: "hidden",
            color: "white",
          }}
        >
          <div className="promo-left d-flex align-items-center">
            <BoxIcon />
            <p
              className="promo-text ml-2"
              style={{
                margin: 0,
                lineHeight: "15px",
                fontWeight: "bold",
                fontSize: "14px",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
            >
              Скидка 25% на первый заказ по промокоду
            </p>
          </div>
          <div className="promo-right d-flex align-items-center">
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "9999px",
                padding: "2px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100px",
              }}
            >
              <p
                className="promo-code"
                style={{ margin: 0, color: "black", marginRight: "0px" }}
              >
                СТАРТ25
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-copy"
              >
                <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
